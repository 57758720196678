.clients-logo {
  background-color: white;
  height: 140px;
  display: grid;
  place-items: center;
  border-radius: 5px;
  border: 1px solid var(--primary);
  padding-left: 10px;

  img {
    max-width: 90%;
    height: auto;
    aspect-ratio: 16 / 9;
    object-fit: contain;
    object-position: center;
  }
}