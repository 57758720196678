.loading-modal {
	padding-top: 4rem;
	position: 'fixed';
	display: 'grid';
	place-items: 'center';
	gap: '2rem';
	background-color: var(--dark);
	z-index: 9999;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;

	// .loading-wrapper {
	// 	display: 'flex';
	// }

	.loading-text {
		position: relative;
		display: inline-block;
		color: #d4d4d4;
		text-align: center;
		font-size: '1.5rem';
		font-weight: 'bold';

		&:before {
			content: attr(data-text);
			position: absolute;
			overflow: hidden;
			white-space: nowrap;
			width: 0;
			color: var(--title);
			animation: fill-1 2s infinite;
      width: 100%;
		}
	}

	@keyframes fill-1 {
		50% {
			width: 100%;
		}
		100% {
			width: 0%;
		}
	}
}

.loading-dots {
  display: inline-block;
	font-size: 88px;
	font-weight: normal;
	text-transform: uppercase;
	color: var(--title);
	animation-name: bounce;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}

.loading-dots:nth-child(1) {
	animation-delay: 0.1s;
}
.loading-dots:nth-child(2) {
	animation-delay: 0.2s;
}
.loading-dots:nth-child(3) {
	animation-delay: 0.3s;
}

@keyframes bounce {
	0% {
		transform: translateY(0px);
	}
	40% {
		transform: translateY(-12px);
	}
	100% {
		transform: translateY(0px);
	}
}

.shimmer {
  color: rgba(#d4d4d4, 0.1);
  background: -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.1, darkblue));
  background: -moz-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.1, darkblue));
  background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.1, darkblue));
  -webkit-background-size: 125px 100%;
  -moz-background-size: 125px 100%;
  background-size: 125px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #d4d4d4;
}
@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}