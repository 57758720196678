.career-section {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  text-align: center;
  min-height: 400px;

  .career-container {
    position: relative;
    z-index: 1;
    padding: 100px 0;
  }

  h3 {
    font-size: 2.8rem;
    color: white;
    text-align: center;
    span {
      color: $secondary;
    }
  }
}

.career-buttons {
  margin-top: 50px;
  .btn {
    margin: 10px;
  }
}