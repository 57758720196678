.footer-row {
  display: flex;
  justify-content: space-between;
  gap: 2.5rem;
}

.footer-contact {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: end;
  align-items: end;
  gap: 2rem;
}

@media screen and (max-width: 590px) {
  .footer-contact {
    grid-template-columns: 1fr;
    justify-content: start;
  }
}

@media screen and (max-width: 1168px) {
  .footer-row {
    gap: 0;
    flex-wrap: wrap;
  }
}