// FILTER BUTTONS CSS
.site-filters {
    margin-bottom: 30px;
	ul {
		margin: 0;
		list-style: none;
	}
	li {
		display: inline-block;
		padding: 0;
		margin-bottom:3px;
		&.btn {
			box-shadow: none;
			-webkit-box-shadow: none;
			outline: none !important;
		}
		input {
			display: none;
		}
		a {
			margin: 0 5px 5px 0;
			display: block;
		}
		&.active{
			[class*="btn"] {
				color: #fff;
				background-color: #EFBB20;
			}
		}
		
	}
	&.center{
		text-align: center;
		ul {
			display: inline-block;
			margin: auto;
		}
		[class*="btn"] {
			display: inline-block;
			margin: 0 5px 10px;
			text-transform: uppercase;
			@include respond('phone'){
				margin: 0 1px 5px;
				padding: 5px 10px;
				font-size: 12px;
			}
		}
	}
	
	// Style 1
	&.style-1{
		margin-bottom: 45px;
		.filters{
			border-bottom: 1px solid rgba(0,0,0,0.2);
			
			li{
				text-transform: capitalize!important;
				margin: 0 15px -1px;
				border: 0;
				border-radius:0;
				&:after{
					content:none;	
				}
				a{
					color: #a6a6a6;
					font-size: 18px;
					margin: 0;
					border-bottom: 3px solid;
					border-color: transparent;
					padding: 0 2px 12px;
					font-weight: 500;
					text-transform: capitalize;
					margin-bottom: 8px;
					&.active{
						color: var(--title);
						border-color: var(--title);
					}
				}
				&.active{
					a{
						color: var(--title);
						border-color: var(--primary);
					}
				}
				&:first-child{
					margin-left: 0;
				}
				&:last-child{
					margin-right: 0;
				}
				@include respond ('tab-port'){
					margin: 0 5px -1px;
					a{
						font-size: 15px;
					}
				}
				@include respond ('phone'){
					margin: 0 0px -6px;
					a{
						font-size: 14px;
					}
				}
			}
		}
	}

	// Style 2
	&.style-2{
		margin-bottom: 45px;
		.filters{
			margin: 0 -5px;
			li{
				text-transform: capitalize!important;
				margin: 0 5px;
				border: 0;
				border-radius:0;
				&:after{
					content:none;
				}
				a{
					color: #777777;
					font-size: 16px;
					margin: 0;
					border: 2px solid rgba(0,0,0,0.1);
					padding: 8px 24px;
					font-weight: 500;
					text-transform: capitalize;
				}
				&.active,
				&:hover{
					a{
						color: #fff;
						background-color: var(--primary);
					}
				}
				&:first-child{
					margin-left: 0;
				}
				&:last-child{
					margin-right: 0;
				}
				@include respond ('tab-port'){
					margin: 0 5px -1px;
					a{
						font-size: 15px;
					}
				}
				@include respond ('phone'){
					margin: 0 0px -6px;
					a{
						font-size: 14px;
					}
				}
			}
		}
	}

	// Style 3
	&.style-3{
		margin-bottom: 45px;
		.filters{
			margin: 0 -5px;
			li{
				text-transform: capitalize!important;
				margin: 0 10px;
				border: 0;
				border-radius:0;
				&:after{
					content:none;
				}
				a{
					color: #777777;
					font-size: 18px;
					margin: 0;
					padding: 0;
					font-weight: 500;
					text-transform: capitalize;
					&:before{
						content: "";
						height: 3px;
						background-color: var(--primary);
						width: 0;
						@include transitionFast;
						position: absolute;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
						opacity: 0.5;
						transform-origin: left;
						-moz-transform-origin: left;
						-ms-transform-origin: left;
						-webkit-transform-origin: left;
						-o-transform-origin: left;
					}
				}
				&.active,
				&:hover{
					a{
						color: var(--primary);
						&:before{
							left: 0;
							right: auto;
							width: 100%;
							transform-origin: right;
							-moz-transform-origin: right;
							-ms-transform-origin: right;
							-webkit-transform-origin: right;
							-o-transform-origin: right;
						}
					}
				}
				&:first-child{
					margin-left: 0;
				}
				&:last-child{
					margin-right: 0;
				}
				@include respond ('tab-port'){
					margin: 0 5px -1px;
					a{
						font-size: 15px;
					}
				}
				@include respond ('phone'){
					margin: 0 0px -6px;
					a{
						font-size: 14px;
					}
				}
			}
		}
	}
}
