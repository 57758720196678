// Header Logo
.logo-header {
	display: table;
	float: left;
	vertical-align: middle;
	padding: 0;
	color: #efbb20;
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
	width: 179px;
	height: 100px;
	position: relative;
	z-index: 9;

	@include respond('tab-port') {
		width: 180px;
		height: 80px;
	}
	@include respond('phone') {
		width: 180px;
	}
	a {
		display: table-cell;
		vertical-align: middle;
	}
	img {
		height: 100px;
		max-width: 179px;
		object-fit: contain;
		@include transitionMedium;
		@include respond('tab-port') {
			max-width: 140px;
			height: 80px;
		}
		@include respond('phone') {
			max-width: 120px;
		}
	}

	// Text Logo
	span {
		font-size: 20px;
		letter-spacing: 20px;
	}
}

// Top Bar
.top-bar {
	background-color: #fff;
	color: #212529;
	padding: 12px 0;
	position: relative;
	border-bottom: 1px solid #d7d7d7;

	.dz-topbar-left {
		float: left;
		li {
			padding-right: 20px;
		}
	}
	.dz-topbar-right {
		float: right;
		li {
			padding-left: 15px;
		}
	}
	.dz-social {
		li {
			a {
				color: inherit;
			}
		}
	}
	.dz-topbar-inner {
		margin-left: -15px;
		margin-right: -15px;
	}
	.dz-topbar-center,
	.dz-topbar-left,
	.dz-topbar-right {
		padding-left: 15px;
		padding-right: 15px;
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				display: inline-block;
				font-size: 15px;
			}
			i {
				margin-right: 5px;
				color: var(--primary);
			}
		}
		.dz-social-icon {
			li {
				padding-left: 0;
			}
		}
	}
	@include respond('tab-port') {
		display: none;
	}
}

// Site Header
.site-header {
	position: relative;
	z-index: 9999;
	.main-bar {
		background: $white;
		width: 100%;
		position: relative;
		box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
	}
	.container {
		position: relative;
	}
	.extra-nav {
		float: right;
		display: flex;
		align-items: center;
		position: relative;
		z-index: 9;
		height: 100px;
		padding-left: 30px;
		@include respond('tab-port') {
			height: 80px;
		}
		@include respond('wide-desktop') {
			margin-left: 10px;
		}
		.btn {
			@include respond('phone') {
				padding-left: 15px;
				padding-right: 15px;
			}
		}

		ul {
			li {
				display: inline-block;
				.icon {
					padding: 0;
					background: transparent;
					min-width: 40px;
					text-align: center;
					height: 40px;
					line-height: 40px;
					border-radius: 4px;
				}
			}
		}
	}
}

// Navbar Toggler
.navbar-toggler {
	border: 0 solid #efbb20;
	font-size: 16px;
	line-height: 24px;
	margin: 40px 0 39px 15px;
	padding: 0;
	float: right;
	outline: none !important;
	@include respond('tab-port') {
		margin: 15px 0 15px 15px;
	}
	@include respond('phone') {
		margin: 15px ​0 15px 15p;
	}
}

// Navicon
.navicon {
	width: 50px;
	height: 50px;
	background: var(--primary);
	box-shadow: 0 5px 15px -10px var(--primary);
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	cursor: pointer;
	border-radius: 0;

	span {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		border-radius: 1px;
		opacity: 1;
		background: #fff;
		left: 10px;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.25s ease-in-out;
		-moz-transition: 0.25s ease-in-out;
		-o-transition: 0.25s ease-in-out;
		transition: 0.25s ease-in-out;

		&:nth-child(1) {
			top: 15px;
			width: 30px;
		}

		&:nth-child(2) {
			top: 23.5px;
			width: 25px;
		}
		&:nth-child(3) {
			top: 33px;
			width: 20px;
		}
	}

	&.open span:nth-child(1) {
		top: 25px;
		-webkit-transform: rotate(135deg);
		-moz-transform: rotate(135deg);
		-o-transform: rotate(135deg);
		transform: rotate(135deg);
	}
	&.open span:nth-child(2) {
		opacity: 0;
		left: -60px;
	}
	&.open span:nth-child(3) {
		top: 25px;
		width: 30px;
		-webkit-transform: rotate(-135deg);
		-moz-transform: rotate(-135deg);
		-o-transform: rotate(-135deg);
		transform: rotate(-135deg);
	}
}

// Header-tb
.header-tb .navicon span {
	background: #fff;
}

// Header Nav
.header-nav {
	padding: 0;

	@include respond('tab-port') {
		overflow-y: scroll;
		position: fixed;
		width: 60px;
		left: -280px;
		height: 100vh !important;
		@include transitionMedium;
		top: 0;
		background-color: $white;
		margin: 0;
		z-index: 99;
	}
	&.show {
		@include respond('tab-port') {
			left: -1px;
			@include transitionSlow;
			margin: 0;
			width: 280px;
			// max-width: 70%;
		}
	}
	.logo-header {
		display: none;
		@include respond('tab-port') {
			padding: 30px 20px;
			float: none;
			height: auto;
			width: 240px;
		}
	}
	.nav {
		float: right;
		padding: 0;
		font-size: 0;
		position: unset;

		& > li {
			margin: 0px;
			font-weight: 400;
			position: relative;
			z-index: 4;
			&.active {
				& > a {
					color: var(--primary) !important;
				}
			}
			&.has-mega-menu {
				position: inherit;
			}
			@include respond('tab-port') {
				border-bottom: 1px solid #eee;
				width: 100%;
			}
			&.sub-menu-down,
			&.has-mega-menu {
				& > a {
					&:after {
						content: '\f078';
						font-size: 9px;
						margin-left: 5px;
						margin-top: 0;
						vertical-align: middle;
						font-weight: 900;
						font-family: 'Font Awesome 5 Free';

						@include respond('tab-port') {
							content: '\f054';
							background-color: var(--primary);
							color: #fff;
							height: 30px;
							line-height: 30px;
							right: 0;
							text-align: center;
							width: 30px;
							z-index: 3;
							float: right;
							font-size: 14px;
							margin: 0;
						}
						&:before {
							@include respond('tab-port') {
								content: '\f054';
							}
						}
					}
				}
				@include respond('tab-port') {
					&.open {
						& > a {
							&:after {
								content: '\f078';
								background: #333;
							}
						}
					}
				}
			}
			& > a {
				color: #071c35;
				font-size: 18px;
				padding: 42px 15px;
				font-family: var(--font-family-title);
				cursor: pointer;
				font-weight: 500;
				display: inline-block;
				position: relative;
				border-width: 0 0 0 1px;
				text-transform: capitalize;
				line-height: 1.1;
				@include respond('tab-land') {
					padding-left: 8px;
					padding-right: 8px;
				}
				@include respond('tab-port') {
					padding: 8px 0;
					display: block;
					line-height: 30px;
					font-size: 16px;
				}
				span {
					display: inline-block;
				}
			}
			.mega-menu,
			.sub-menu {
				background-color: $white;
				display: block;
				left: 0;
				list-style: none;
				opacity: 0;
				padding: 5px 0;
				position: absolute;
				visibility: hidden;
				width: 220px;
				z-index: 10;
				margin-top: 20px;
				box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.1);
				text-align: left;
				@include transitionSlow;
				@include respond('tab-port') {
					display: none;
					position: static;
					visibility: visible;
					width: auto;
					background: transparent;
					box-shadow: none;
					margin: 0;
					border-top: 1px solid #eee;
					padding: 10px 0;
					@include transitionNone;
					& > li > a i {
						color: #fff !important;
						height: 30px;
						line-height: 30px;
						right: 0;
						text-align: center;
						width: 30px;
						z-index: 3;
						font-size: 14px !important;
						margin: 0 !important;
						background: #aaa;
					}
				}
				@include respond('phone-land') {
					transition: none;
				}
				li {
					position: relative;

					a {
						color: #212529;
						display: block;
						font-size: 15px;
						padding: 12px 25px;
						line-height: 1.3;
						text-transform: capitalize;
						position: relative;
						transition: all 0.15s linear;
						-webkit-transition: all 0.15s linear;
						-moz-transition: all 0.15s linear;
						-o-transition: all 0.15s linear;
						font-weight: 500;

						@include respond('tab-port') {
							padding: 2px 0 1px 16px;
							display: block;
							line-height: 30px;
							position: relative;

							&:after {
								content: '\f054';
								font-family: 'Font Awesome 5 Free';
								width: auto;
								height: auto;
								background: 0 0 !important;
								top: 3px;
								color: #262f5a;
								font-size: 12px;
								position: absolute;
								left: 0;
								font-weight: 700;
							}
						}
					}
					&:hover > a {
						color: var(--title);
						background-color: #f1f1f1;

						@include respond('tab-port') {
							padding: 2px 0 1px 16px;
							background: transparent;
						}
					}
				}
				li > .sub-menu {
					left: 220px;
					@include transitionMedium;

					@include respond('tab-port') {
						display: none;
					}
				}
				@include respond('tab-port') {
					.mega-menu,
					.sub-menu {
						border-top: 0;
						padding-left: 20px;
						li a:after {
							width: 5px;
							content: '';
							height: 5px;
							background: #262f5a !important;
							border-radius: 4px;
							top: 13px;
						}
					}
				}
			}
			.sub-menu {
				li > a > i {
					color: inherit;
					display: block;
					float: right;
					font-size: 18px;
					opacity: 1;
					margin-top: 0px;
				}
			}
			.mega-menu {
				display: flex;
				right: 0px;
				width: 100%;
				z-index: 9;
				padding: 0 calc((100% - 1200px) / 2);
				& > li {
					display: block;
					padding: 30px 20px 25px;
					position: relative;
					vertical-align: top;
					width: 25%;
					@include respond('tab-port') {
						width: 100%;
						padding: 0;
					}
					& > a {
						color: $dark;
						display: block;
						padding: 0 25px;
						font-size: 16px;
						font-weight: 600;
						border-bottom: 1px solid rgba(0, 0, 0, 0.1);
						padding-bottom: 15px;
						& > i {
							font-size: 14px;
							margin-right: 5px;
							text-align: center;
							width: 15px;
						}
						&:after {
							content: none;
						}
						@include respond('tab-port') {
							display: none;
						}
					}
					ul {
						list-style: none;
						margin: 10px 0 0 0;
						padding: 0;
						width: 100%;
						@include respond('tab-port') {
							margin: 0;
						}
					}
					&:last-child:after {
						display: none;
					}
					&:hover > a {
						background-color: transparent;
						color: $dark;
						padding: 0 25px 15px;
					}
					li i {
						font-size: 14px;
						margin-right: 5px;
						text-align: center;
						width: 15px;
					}
				}
				@include respond('tab-port') {
					display: none;
					padding: 10px 0;
				}
			}
			&.active {
				& > a {
					color: var(--primary);
				}
			}
			&:hover {
				& > a {
					color: var(--secondary);
					@include respond('tab-port-min') {
						span {
							-webkit-animation: upToDown 0.3s forwards;
							-moz-animation: upToDown 0.3s forwards;
							animation: upToDown 0.3s forwards;
						}
					}
				}
				& > .mega-menu,
				& > .sub-menu {
					opacity: 1;
					visibility: visible;
					margin-top: 0;
					@include transitionSlow;
					@include respond('tab-port') {
						@include transitionNone;
					}
					li:hover > .sub-menu {
						opacity: 1;
						visibility: visible;
						margin-top: 0;
						top: -1px;
					}
				}
			}
			&.open {
				.mega-menu,
				.sub-menu {
					opacity: 1;
					@include transitionNone;
					@include respond('tab-port') {
						display: block;
					}
				}
				.open {
					&.menu-item-has-children > a:before {
						transform: rotate(90deg);
						-moz-transform: rotate(90deg);
						-webkit-transform: rotate(90deg);
						-ms-transform: rotate(90deg);
						-o-transform: rotate(90deg);
					}
					.mega-menu,
					.sub-menu {
						display: block;
						opacity: 1;
						@include transitionNone;
					}
				}
			}
		}
		@include respond('laptop') {
			& > li:nth-last-child(3),
			& > li:nth-last-child(2),
			& > li:last-child {
				.sub-menu .sub-menu {
					left: -220px;
				}
			}
		}
		@include custommq($max: 1199px) {
			& > li:last-child {
				.sub-menu {
					left: auto;
					right: 0;
				}
			}
		}
		@include respond('tab-port') {
			float: none;
			padding: 0 20px;
		}
	}
	.dz-social-icon {
		display: none;
		text-align: center;
		li {
			display: inline-block;
			margin: 0;
		}
		a {
			width: 40px;
			height: 40px;
			padding: 0;
			color: var(--primary);
			border: 1px solid #eee;
			line-height: 38px;
			text-align: center;
			border-radius: 40px;
		}
		@include respond('tab-port') {
			display: block;
			padding-top: 30px;
			padding-bottom: 20px;
		}
	}
}

// Header Transparent{
.header-transparent {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;

	.top-bar,
	.main-bar {
		background: transparent;
	}
	.container-fluid {
		padding-left: 80px;
		padding-right: 80px;
		@include respond('tab-land') {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	// Side Menu Btn
	.side-menu-btn {
		.menu-icon-in {
			span {
				background-color: $white;
			}
		}
	}
	.is-fixed {
		.side-menu-btn {
			.menu-icon-in {
				span {
					background-color: $white;
				}
			}
		}
	}
	@include respond('tab-port-min') {
		.nav {
			& > li {
				& > a {
					color: $white;
				}
			}
		}
	}
	.navbar-toggler {
		span {
			background: $white;
		}
	}
	.is-fixed {
		.nav {
			& > li {
				& > a {
					color: $dark;
				}
			}
		}
		.navbar-toggler {
			span {
				background: $white;
			}
		}
	}

	// Top Bar
	.top-bar {
		border-color: $white;
		.dz-topbar-left,
		.dz-topbar-right {
			li,
			i {
				color: $white;
			}
		}
	}
}

// Is Fixed
.is-fixed {
	.main-bar {
		position: fixed;
		top: 0;
		left: 0;
		box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
		background: $white;
		transition: all 0.5s;
	}
}

// Mobile Nav
@include respond('tab-port') {
	.mo-left {
		.header-nav {
			overflow-y: scroll;
			position: fixed;
			width: 60px;
			left: -280px;
			height: 100vh !important;
			transition: all 0.5s;
			-webkit-transition: all 0.5s;
			-moz-transition: all 0.5s;
			-o-transition: all 0.5s;
			top: 0;
			background-color: #fff;
			margin: 0;
			z-index: 99;
			&.nav-dark {
				background-color: #202020;
			}
			&.show {
				left: -1px;
				transition: all 0.8s;
				-webkit-transition: all 0.8s;
				-moz-transition: all 0.8s;
				-o-transition: all 0.8s;
				margin: 0;
				width: 280px;
				// max-width: 70%;
			}
			.logo-header {
				display: block;
				float: none;
				height: auto !important;
				max-width: 100%;
				padding: 25px 20px;
				width: 100% !important;
				img {
					max-width: unset;
					width: 120px;
					height: 60px;
					vertical-align: middle;
				}
			}
			li {
				&.open {
					a {
						position: relative;
					}
				}
			}
		}
		.navbar-toggler {
			&.open {
				z-index: 99;
				&:after {
					background-color: rgba(0, 0, 0, 0.6);
					content: '';
					left: 0;
					position: fixed;
					right: -20px;
					top: -63px;
					transform: scale(100);
					-o-transform: scale(100);
					-moz-transform: scale(100);
					-webkit-transform: scale(100);
					width: 100%;
					z-index: -1;
					transition: all 0.5s;
					transform-origin: top right;
					margin: 0 0px 0px 20px;
					-webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
					box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
				}
				span {
					background: #fff;
				}
			}
		}
	}
}
.text-black {
	.nav {
		& > li {
			& > a {
				color: $dark;
			}
		}
	}
	.navbar-toggler {
		span {
			background: $dark;
		}
	}
}

/* Start Header is-fixed Animation */
.main-bar {
	-webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
	-khtml-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
	-moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
	-ms-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
	-o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
	transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
}
.is-fixed .main-bar {
	-webkit-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
	-khtml-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
	-moz-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
	-ms-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
	-o-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
	animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
}
@-moz-keyframes headerSlideDown {
	0% {
		margin-top: -150px;
	}
	100% {
		margin-top: 0;
	}
}
@-ms-keyframes headerSlideDown {
	0% {
		margin-top: -150px;
	}
	100% {
		margin-top: 0;
	}
}
@-webkit-keyframes headerSlideDown {
	0% {
		margin-top: -150px;
	}
	100% {
		margin-top: 0;
	}
}
@keyframes headerSlideDown {
	0% {
		margin-top: -150px;
	}
	100% {
		margin-top: 0;
	}
}
/* Header is-fixed Animation End */

// Header Tabs Wraper
.header-tabs-wraper {
	.header-style-bx {
		display: flex;
		width: 100%;

		.header-style-tabs {
			width: 15%;
			position: relative;
			z-index: 1;
			padding: 20px 0 20px 20px;
			background: #f1f1f1;

			.header-tabs {
				padding: 15px 25px;
				&:hover {
					padding: 15px 25px;
				}
				&:after {
					content: none;
				}
				&.active {
					color: #000;
					background: #fff;
				}
			}
		}
		@include respond('tab-port') {
			display: block;
			.header-style-tabs {
				width: 100%;
				padding: 15px;
				display: none;
			}
		}
	}
	.header-style-content {
		width: 85%;
		background: #fff;

		.menu-list {
			display: flex;
			text-align: left;

			& > li {
				width: 100%;
				padding: 0 10px;

				.title {
					display: block;
					font-size: 16px;
					font-weight: 600;
					color: #000;
					padding: 10px 25px 10px;
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				}
				& > ul {
					padding: 12px 0;
					& > li {
						i {
							margin-right: 5px;
						}
					}
				}
				&:last-child {
					border-right: 0;
				}
				@include respond('tab-port') {
					border-right: 0;
					padding: 0;
					& > ul {
						padding: 0;
					}
					.title {
						display: none;
					}
				}
			}
		}
		@include respond('tab-port') {
			width: 100%;
			.menu-list {
				display: block;
			}
		}
	}
	.header-tabs-content {
		display: none;
		&.show {
			display: block;
		}
		@include respond('tab-port') {
			display: block;
		}
	}
}
